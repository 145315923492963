<template >
  <fade-transition>
    <div class="fifth-text" v-bind:class="[isMobile() ? 'fifth-text-mobile' : 'fifth-text-pc']" v-if="showDiffBalance">
      <span :class="[winLose ? 'add-text' : 'minus-text']">
        {{ getSign()}} {{ displayNumber | addComma }}
      </span>
    </div>
  </fade-transition>
</template>

<script>
import FadeTransition from "@/components/common/FadeTransition";
export default {
  name: "AnimatedNumber",
  components: {
    FadeTransition,
  },
  props: {
    incremental: {
      required: true,
      default: 0,
    },
    winLose: {
      required: false,
      type: Boolean,
    },
    showDiffBalance: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      displayNumber: 0,
      interval: false,
    };
  },
  ready: function () {
    this.displayNumber = this.incremental ? this.incremental : 0;
  },
  methods: {
    getSign() {
      let r = '';
      if (this.displayNumber > 0 ){
        if (this.winLose == true) {
          r = '+'
        } else if  (this.winLose == false)
        {
          r = '-'
        }
      }
      return r
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    incremental: function () {
      clearInterval(this.interval);
      if (this.incremental == this.displayNumber) {
        return;
      }

      this.interval = window.setInterval(
        function () {
          if (this.displayNumber != this.incremental) {
            var change = (this.incremental - this.displayNumber) / 5;

            change = change >= 0 ? Math.ceil(change) : Math.floor(change);

            this.displayNumber = this.displayNumber + change;
          }
        }.bind(this),
        20
      );
    },
  },
  filters: {
    addComma(val) {
      return val.toLocaleString();
    },
  },
};
</script>

<style>
.minus-text {
  font-weight: bold;
  background: -webkit-linear-gradient(#ff0000, #bc1212c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 -1px 2px #fff, 0px 0px 10px #ff0000ad, 20px 0px 20px #ff0000cf,
    -20px 0px 20px #ff0000cc, 0 0px 40px #ff0000d1;
}

.add-text {
  font-weight: bold;
  background: -webkit-linear-gradient(#eda649, #bc7212c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 -1px 2px #fff, 0px 0px 10px #ffff00ad, 20px 0px 20px #ff8000cf,
    -20px 0px 20px #ff8000cc, 0 0px 40px #ff0000d1;
}

.fifth-text {
  opacity: 1;
  color: #f0eba3;
  text-align: center;
  border-top: 1px solid #eda649;
  border-bottom: 1px solid #eda649;
  box-shadow: inset 0px 0px 25px 10px #000000a1;
  border-radius: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.fifth-text-pc {
  font-size: 2rem;
  position: absolute;
  left: 35%;
  right: 35%;
  bottom: 10%;
}

.fifth-text-mobile {
  font-size: 1rem;
  position: absolute;
  left: 25%;
  right: 25%;
  bottom: 85%;
}
</style>
