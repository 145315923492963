<!--=========================================================================================
  File Name: MoveLobbyShoe.vue
  Description: Move Lobby Shoe Component
==========================================================================================-->
<style>
.ml-f{
  font-size: 1em;
}
</style>
<template>
<div class="sd-container">
    <div class="row">
        <div class="col-md-4">
          {{$t('lobby.gameType.' + room.type)}}
        </div>
        <div class="col-md-4">
            {{room.roomno}}
        </div>
        <div class="col-md-4">
            {{ getRoundNumber(room.roomorder) }}
        </div>
    </div>
    <div class="card content"  @click="changeRoom(room.roomno, room.type)" style="height: 10vh;">
        <div class="cardbig-road grid-x grid-x-repeat-big" ref="big-road-tbl">
            <div class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-big">
                <div class="flex-column" v-for="(shoeM,x) in bigRoadMatrix" :key="x">
                    <div v-for="(shoe, i) in shoeM" v-bind:key="shoe.index + '-' + i" class="parent-shoe-size">
                        <div class="big-road-shoe child-shoe-size"
                            v-bind:class="[{ animate_latest: x == bigRoadMatrix.length - 1 && (shoe.shoe !='' && i == $getLasttIndex(bigRoadMatrix, x) )}, shoe.shoe + '-big-road']">
                            <span style ="font-size: 1.5vh" v-if="shoe.count > 0">{{ shoe.count }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import router from '../../../routes/router';
export default {
    name: 'MoveLobbyShoe',
    components: {},
    data() {
        return {
            showRightArrow: false,
            showLeftArrow: true,
        }
    },
    props: {
        room: {
            required: true,
            type: Object
        }
    },
    computed: {
        bigRoadMatrix() {
            let matrix = [],
                prevShoe = '',
                columnIdx = -1,
                rowIdx = 1,
                sameShoeCnt = 0,
                tempColumnIdx = -1,
                tmpInd = 5,
                tieCnt = 0,
                idx = 0;
            const tieShoe = ['i', 'j', 'k', 'l']
            this.room.shoe.split('').forEach((shoe) => {
                if (!(tieShoe.indexOf(shoe) !== -1)) { // If shoe is not tie
                    if (!((this.$prevShoeArr(prevShoe)).indexOf(shoe) !== -1)) { // create new column
                        if (matrix[columnIdx + 1]) { // check if there's an over shoe, overwrite 1st column
                            matrix[columnIdx + 1][0] = {
                                shoe,
                                count: 0
                            }
                        } else {
                            matrix.push([{
                                shoe,
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }])
                        }
                        rowIdx = 1; // reset row index to 1 (next vertical shoe)
                        sameShoeCnt = 0 // reset same shoe count
                        columnIdx++; // increment column index
                        tempColumnIdx = columnIdx;
                        tieCnt = 0;
                    } else { // normal add
                        if (sameShoeCnt > rowIdx) {
                            matrix.push([{
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }, {
                                shoe: '',
                                count: 0
                            }])
                            tempColumnIdx++
                            matrix[tempColumnIdx][rowIdx] = {
                                shoe,
                                count: 0
                            }
                            if (tmpInd < 5) { //
                                if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                    tempColumnIdx--
                                    rowIdx++
                                    tmpInd = rowIdx
                                }
                            }
                        } else {
                            if (matrix[tempColumnIdx] && matrix[tempColumnIdx][rowIdx].shoe == '') {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0
                                }
                            } else {
                                tmpInd = rowIdx - 1
                                // check if the matrix has enough length to handle dragon tail
                                if (matrix.length < tempColumnIdx) {
                                    matrix.push([{
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }, {
                                        shoe: '',
                                        count: 0
                                    }])
                                }

                                matrix.push([{
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }, {
                                    shoe: '',
                                    count: 0
                                }])
                                tempColumnIdx++
                                rowIdx--
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe,
                                    count: 0
                                }

                                // Always check the bottom index
                                if (tmpInd < 5) {
                                    if (matrix[tempColumnIdx][rowIdx + 1].shoe == '') {
                                        tempColumnIdx--
                                        rowIdx++
                                        tmpInd = rowIdx
                                    }
                                }
                            }
                        }
                        if (rowIdx < tmpInd)
                            rowIdx++; // increment row index
                    }

                    if (columnIdx >= 0 && rowIdx > 0) {
                        idx++;
                        matrix[columnIdx][rowIdx - 1].index = idx;
                    }

                    prevShoe = shoe // set current shoe as prev
                    sameShoeCnt++; // same Shoe
                    tieCnt = 0 // reset tie counting.
                } else {
                    tieCnt++;
                    if (tempColumnIdx > 0) { // if Tie is the first result
                        if (sameShoeCnt > rowIdx) {
                            if (rowIdx > -1 && tempColumnIdx > -1) {
                                matrix[tempColumnIdx][rowIdx] = {
                                    shoe: prevShoe,
                                    count: tieCnt
                                }
                            }
                        } else {
                            matrix[tempColumnIdx][rowIdx - 1] = {
                                shoe: prevShoe,
                                count: tieCnt
                            }
                        }
                    }
                }
            })
            return matrix
        },
    },
    methods: {
        getRoundNumber(roomorder) {
            try {
                return roomorder.split('-')[1] || 0
            } catch (e) {
                return 0
            }
        },
        changeRoom(roomno, roomtype) {
            var isMobile = false;
            if(/Android|android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                isMobile = true;
            }
            this.$store.commit('gameModule/SET_ROOM_DATA', {
                roomno,
                roomorder: '',
                state: '2',
                shoe: '',
                time: 0,
                result: null
            })
            this.$socket.client.emit('switch_room', {
                roomno,
                level: this.$store.state.gameModule.currentLevel,
                mobile: isMobile
            })
            this.$emit('initBettingSetFalse');

            if (isMobile) {
              if (router.currentRoute.path != `/m/game`) {
                if (roomtype == 'DT') {
                    this.$router.push(`/m/game/dt/${this.$store.state.gameModule.game.code}/${roomno}`);
                } else {
                    this.$router.push(`/m/game/${this.$store.state.gameModule.game.code}/${roomno}`);
                }
              }
            } else {
              if (router.currentRoute.path != `/game`) {
                if (roomtype == 'DT') {
                    this.$router.push(`/game/dt/${this.$store.state.gameModule.game.code}/${roomno}`);
                } else {
                    this.$router.push(`/game/${this.$store.state.gameModule.game.code}/${roomno}`);
                }
              }
            }
        },
    }
}
</script>

<style>
.big-road-tbl td {
    border: 1px solid #D8D8D8;
    border-top: none !important;
}

.big-road-tbl {
    display: block;
    overflow: hidden;
}

.cardbig-road {
    overflow: hidden;
}

.sd-container {
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(190, 174, 147, 0.6);
    float: left;
    width: 200px;
    margin: 6px;
    cursor: pointer;
    height: auto;
}

.game-type {
    position: absolute;
}

.parent-shoe-size {
    height: 1.61vh;
    width: 1.61vh;
}

.child-shoe-size {
    height:1.61vh;
    width: 1.54vh;
}
</style>
