<template>
  <component
    :is="type"
    :tag="tag"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
    v-on="hooks"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 1000,
    },
    group: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "div",
    },
  },
  computed: {
    type() {
      return this.group ? "transition-group" : "transition";
    },
    hooks() {
      return {
        beforeEnter: this.setDuration,
        afterEnter: this.cleanUpDuration,
        beforeLeave: this.setDuration,
        afterLeave: this.cleanUpDuration,
        leave: this.setAbsolutePosition,
      };
    },
  },
  methods: {
    setDuration(el) {
      el.style.animationDuration = `${this.duration}ms`;
    },
    cleanUpDuration(el) {
      el.style.animationDuration = "";
    },
    setAbsolutePosition(el) {
      if (this.group) {
        el.style.position = "absolute";
      }
    },
  },
};
</script>

<style>
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
    animation-timing-function: ease;
  }

  20% {
    opacity: 0.25;
    transform: scale(0.4);
    animation-timing-function: ease;
  }

  50% {
    opacity: 0.5;
    transform: scale(0.6);
    animation-timing-function: ease;
  }

  75% {
    opacity: 0.75;
    transform: scale(0.8);
    animation-timing-function: ease;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}
</style>
