<!--=========================================================================================
  File Name: MoveLobbyClosed.vue
  Description: Move Lobby Closed Component
==========================================================================================-->
<template>
    <div class="sd-container">
        <div class="row">
            <div class="col-md-4">
                {{room.type}}
            </div>
            <div class="col-md-4">
                {{room.roomno}}
            </div>
            <div class="col-md-4"></div>
        </div>
        <div class="card content" style="padding: 0px">
           <div class="cardbig-road" ref="big-road-tbl" >
               <div class="rs-ctnr closed-box" >
                   <span class="closed-text">{{ $t('gameroom.closeTextHeader') }}</span>
               </div>
           </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'MoveLobbyClosed',
    data() {
        return {

        }
    },
    props: {
        room:{required: true, type: Object}
    },
    methods: {
    }
}
</script>
<style type="text/css">
.sd-container{
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    /* background-color: #161616; */
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(190, 174, 147, 0.6);
    float: left;
    width: 215px;
    margin:6px;
    cursor: pointer;


}

.room-list .closed-box {
    text-align: center;
    width: 100%;
    height: 10vh;
    background-image: url(/assets/images/closed-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 3vw;
    font-weight: 700;
    cursor: auto;
}

</style>
